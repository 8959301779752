<template>

    <v-row class="mt-4">
        <v-col cols="12">
            <v-card
            v-if="loggedIn"
            class="mx-auto"
                >
    
                <v-toolbar
                    ligth
                    cards                
                    flat
                    color="green lighten-4"
                >
                    <v-card-title class="text-h6 font-weight-regular">
                    Accounts
                    </v-card-title>
                    <v-spacer> </v-spacer>
                    <v-btn
                        :disabled="currentUser.roleLevel < 500"
                        color="primary"
                        dark
                        fab
                        class="mt-10"
                        @click="addItem"
                    >
                        <v-icon dark>
                            mdi-playlist-plus
                        </v-icon>
                    </v-btn>             
                </v-toolbar>
                <v-card-title>
                    <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                    ></v-text-field>
                    </v-card-title>
                        <v-dialog v-model="ctrlConfirmDel.isVisisble" :width="ctrlConfirmDel.width">
                            <ctrlConfirmDel 
                                ref="ctrlConfirmDel" 
                                :text= "ctrlConfirmDel.text"
                                :delName="ctrlConfirmDel.delName"
                                @deleteItemConfirm="deleteItemConfirm"
                                @close="closeConfirmDel">
                            </ctrlConfirmDel>              
                        </v-dialog>
                        <v-dialog v-model="ctrlAEU.isVisisble" :width="ctrlAEU.width" >
                            <ctrlAddEditUser 
                                ref="ctrlAEU" 
                                :user="ctrlAEU.user"
                                :options="ctrlAEU.options"
                                :availableRoles="availableRoles"
                                :isVisible.sync="ctrlAEU.isVisisble"                                
                                @close="ctrlAEU.isVisisble = false">
                            </ctrlAddEditUser>              
                        </v-dialog>       
                        <v-dialog v-model="ctrlAEC2U.isVisisble" :width="ctrlAEC2U.width" >
                            <ctrlAddEditCompany2User 
                                ref="ctrlAEC2U" 
                                :user="ctrlAEC2U.user"
                                :options="ctrlAEC2U.options"
                                :isVisible.sync="ctrlAEC2U.isVisisble"                              
                                @close="ctrlAEC2U.isVisisble = false">
                            </ctrlAddEditCompany2User>              
                        </v-dialog>       
                                         
                    <v-data-table
                    :headers="headers"
                    :items="items"
                    single-expand
                    :expanded.sync="expanded"
                    :search="search"
                    show-expand
                    >
                    <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-subheader class="mt-2">Keycodes:</v-subheader>
                        <v-container class="mb-4">
                        <v-row>
                            <v-col v-for="index in keyCodes.length" :key="index" >
                               <v-row justify="center"> {{keyCodes[index-1]}}</v-row>
                               <v-row justify="center">
                                   <v-checkbox 
                                        v-model="item.code[index-1]" 
                                        :disabled="(!access.edit_accept || item.roleLevel > currentUser.roleLevel)"
                                        @change="keyCodeChanged(item)"
                                    >
                                    </v-checkbox>
                                </v-row>
                            </v-col>
                            <v-col>
 
                            </v-col>
                                                                      
                        </v-row>
                        </v-container>
                   
                    </td>
                    </template>
                    
                    <template v-slot:item.enabled="{ item }">
                        <v-switch
                            v-model="item.enabled"
                            :disabled="(!access.edit_accept || item.roleLevel > currentUser.roleLevel || currentUser.name == item.username)"
                            @change="enabledChanged(item)"
                            
                        ></v-switch>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon                            
                            
                            :disabled="(!access.edit_accept || item.roleLevel > currentUser.roleLevel) && currentUser.id != item.id"
                            class="mr-2"
                            @click="editItem(item)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            :disabled="!access.delete_accept || item.roleLevel > currentUser.roleLevel"
                            
                            @click="deleteItem(item)"
                        >
                            mdi-delete
                        </v-icon> 
                    </template>               
                    <template  v-slot:item.links="{ item }">
                        <v-icon
                            v-if="item.roleLevel<900"
                            :disabled="currentUser.roleLevel < 750 || item.roleLevel > currentUser.roleLevel"
                            large
                            @click="linkCompany(item)"
                        >
                            mdi-link-box
                        </v-icon>
                    </template>
                </v-data-table>
  
            </v-card>
        </v-col>
    </v-row>

</template>


<script>

// import helper from "../../../helper/index"

export default {
    
    components: {
        ctrlAddEditUser: () => import('../../../components/ctrl/addEditUser.vue'),
        ctrlAddEditCompany2User: () => import('../../../components/ctrl/addEditCompany2User.vue'),
        ctrlConfirmDel: () => import('../../../components/ctrl/confirmDeleteItem.vue'),
        // dlgShowMap,
        // dlgImportFields,
    },  


    data () {
        return {
            name: "AccountsViewer",
            // access: {
            //     edit_accept: undefined
            // },
            expanded: [],
            errMsg:'',
            defaultKeyCodes:['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'],
            search: '',
            headers: [
                { text: 'Actions', value: 'actions', sortable: false },
                { text: 'Name', value: 'username',},
                { text: 'E-Mail', value: 'email' },
                { text: 'Role', value: 'roleCaption' },
                { text: 'Registriert seit', value: 'registered' },
                { text: 'Letzter Login', value: 'last_login' },
                 { text: 'Betriebe',sortable: false, value: 'links' },
                { text: 'Aktiv',sortable: false, value: 'enabled' },
                { text: '', value: 'data-table-expand' },
    
            ],  
            editedIndex: -1,
            ctrlAEU:{
                isVisisble: false,
                width: 900,
                user:{},
                options:{
                    title: 'Benutzer',  
                    isEdit: false
                }
            },
           ctrlAEC2U:{
                isVisisble: false,
                width: 900,
                user:{},
                options:{
                    title: 'Linked Company',  
                    isEdit: false
                }
            },             
            ctrlConfirmDel:{
                width: 500,
                isVisisble: false,
                text: "Wollen Sie den User wirklich löschen?" ,
                delName: '',
                item: undefined
            },
        }
    },

  
    computed: {


        loggedIn: function() {        
            return this.$store.state.auth.status.loggedIn;       
        },

        currentUser() {
            console.debug("user", this.$store.state.auth.user)
            return this.$store.state.auth.user;
        },

        items(){
            
            return this.$store.state.management.accounts;
        },

        access(){
            
            return this.$store.state.management.access;
        },  
        
        availableRoles(){
            
            return this.$store.state.management.availableRoles;
        },          

        keyCodes: function(){
            var codes = [];
            console.debug('codes', this.currentUser)
            for(var item of this.defaultKeyCodes){
                    codes.push(item)
                    if(item === this.currentUser.maxKey)
                        break
            }
            //console.debug('codes', codes)
            return codes;
        },

         
    },
    methods:{


        addItem () {
            console.debug('addItem');
            this.ctrlAEU.options.isEdit = false;
            this.ctrlAEU.user = undefined
            this.ctrlAEU.isVisisble = !this.ctrlAEU.isVisisble;
        },        
        editItem (item) {
            console.debug('editItem',item);
            this.ctrlAEU.options.isEdit = true;
            this.ctrlAEU.user = item;
            this.ctrlAEU.isVisisble = !this.ctrlAEU.isVisisble;
        },


        deleteItem (item) {
            console.debug('deleteItem', item);
            this.ctrlConfirmDel.delName = item.username
            this.ctrlConfirmDel.isVisisble = true;
            this.ctrlConfirmDel.item = item;
        },

        closeConfirmDel(){
             console.debug('closeConfirmDel');
             this.ctrlConfirmDel.isVisisble = false;        
        },        

        deleteItemConfirm () {
            console.debug('deleteItemConfirm');
           this.$store.dispatch("management/deleteApiSetStore", {path:"api/management/user", param:{ store:"accounts", 'id':this.ctrlConfirmDel.item.id}})
            .then((response)=>{ 

                console.log("User deleted:", response)
                this.ctrlConfirmDel.isVisisble = false; 

            })
            .catch((error)=>{
                console.error(error);
            })

        },

        enabledChanged(item){
            console.debug('enabledChanged', item);
            this.$store.dispatch('management/putApiSetStore', {path:"api/management/user", param:{ store:"accounts", data:item}})
            .then((response)=>{ 

                console.log("enabledChanged:", response)

            })
            .catch((error)=>{
                console.error(error);
            })

 
        },

        keyCodeChanged(item){
            console.debug('keyCodeChanged', item);
            
            item.securitymask = item.code.reduceRight((res, x) => res << 1 | x)
            this.$store.dispatch('management/putApiSetStore', {path:"api/management/user", param:{ store:"accounts", data:item}})
            .then((response)=>{ 

                console.log("keyCodeChanged:", response)

            })
            .catch((error)=>{
                console.error(error);
            })
        },

        linkCompany(item){
          console.debug('linkCompany', item);
          this.ctrlAEC2U.user = item;
          this.ctrlAEC2U.isVisisble = true;
            
        },

 

        refreshContent: function(){
            console.debug('refreshContent');
 
            this.$store.dispatch("management/getApiSetStore", {path:"api/management/access", param:{store:"access", content:"accounts"}
            }).then( ()=>{ 
                return this.$store.dispatch("management/getApiSetStore", {path:"api/management/accounts", param:{store:"accounts"}})
            }).then( ()=>{ 
                return this.$store.dispatch("management/getApiSetStore", {path:"api/management/availableroles", param:{store:"availableRoles"}})
            }).then( ()=>{    
                   console.debug('sdsdsd')  
            }).catch((error)=>{
                console.error(error);
            })
 
        },
    },
    mounted() {

        //this.refreshNavbar();
        console.debug('mounted');
        this.refreshContent();
    }
}
</script>